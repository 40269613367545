const styles = (theme) => ({
  root: {
    position: 'absolute',
  },
  flex: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: 0,
    marginRight: 0,
  },
  icon: {
    margin: theme.spacing(2),
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  logo: {
    textTransform: 'lowercase',
    fontSize: 25,
  },
  height: {
    height: 50,
    justifyContent: 'center',
  },
})

export default styles
