const styles = () => ({
  root: {
    margin: '0 10px'
  },
  text: {
    fontSize: 14
  }
})

export default styles
